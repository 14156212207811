.launchpad-container {
  display: flex;
  flex-direction: column;
  /* padding: 12px; */
}

.launchpad-container h1 {
  color: white;
}

.launchpad-intro {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.launchpad-intro p,
h4 {
  color: white;
}

.launchpad-nav {
  display: flex;
  height: 45px;
  background-color: white;
  /* width: 70%; */
  margin: 20px auto;
  border-radius: 30px;
  justify-content: space-around;
  align-items: center;
}

.launchpad-nav-btn {
  color: #0058ff;
  text-decoration: none;
  transition-duration: 1s;
  font-weight: 500;
}

.launchpad-nav-btn:hover {
  text-decoration: none;
  color: #191c26;
  transition-duration: 0.6s;
  border-top: 1.5px solid #191c26;
  transition: ease-in-out;
}

.launchpad-nav-active-btn {
  border-top: 1.5px solid #191c26;
  color: #191c26;
  transition-duration: 1s;
}

.launchpad-list-container {
  width: 94%;
  display: flex;
  margin: 0 auto;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  justify-content: center;
}

.launchpad-list-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-radius: 18px;
  width: 300px;
  margin: 12px;
  background-color: white;
  /* height: 370px; */
}

.launchpad-list-item-blue{
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border-radius: 18px;
  width: 300px;
  margin: 12px;
  background-color: #040422;
}

/* Extra small 
devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .launchpad-nav {
    width: 94%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .launchpad-list-container {
    justify-content: center;
  }
}
