.recentTradeActiveOrders {
  width: 100%;
  border-bottom: 1px solid #0c152d;
  padding: 0px 10px;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.recentTradeActiveOrdersWrapper::-webkit-scrollbar {
  display: none;
}