.alpha-left-nav {
  width: 250px;
}

.alpha-grid {
  height: inherit;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.alpha-grid a {
  display: flex;
  flex-direction: column;
  background-color: #050d22;
  margin: 0.5px;
  margin-bottom: 0.6px;
  color: #8e8e8f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-align: center;
  padding: 20px 10px;
  line-height: 1em;
  font-size: 14px;
}

.alpha-grid a:hover {
  background-color: #050a1a;
}
.alpha-grid-active .active {
  background-color: #0058ff;
  color: white;
}

.al_btn_out p {
  border: 1px solid #0058ff;
  padding: 10px;
  margin-bottom: 8px;
}

.al_btn_out p:hover {
  border: 1px solid transparent;
  background-color: #0058ff;
  color: white;
}

.gry_heads {
  background-color: #495677;
  padding: 7px 10px;
  margin-top: 10px;
  margin-bottom: 3px;
  color: white;
  text-align: left;
}
.gry_items p {
  color: #b9b9b9;
  padding-left: 12px;
  font-size: 14px;
}

.blu_btn_sign {
  display: flex;
  background-color: #0058ff;
  padding: 3px 10px;
  margin-top: 10px;
  color: white;
  text-align: left;
}

.al_tech_dp {
  background-color: #0058ff;
  padding: 10px;
  text-align: center;
}
.al_tech_img {
  width: 100%;
  height: 200px;
  /* margin-top: 10px; */
}
