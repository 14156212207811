.Alphatrade {
  display: flex;
  color: #e1e1e1;
}

.toolbox {
  display: inherit;
  flex-direction: column;
  border: 1px solid #969090;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  scroll-behavior: smooth;
  overflow-y: scroll;
}
.toolbox::-webkit-scrollbar {
  display: none;
}
.toolbox p:hover {
  color: white;
  -webkit-text-stroke: 1px white;
}

.graph_container {
  display: flex;
  background-color: #050d22;
  border-radius: 8px;
  height: 70vh;
  padding: 8px;
  color: #969090;
}

.time_diff_container {
  display: flex;
  /* background-color: red; */
}

.time_diff_container p {
  flex: 1;
  border: 1px solid #969090;
  margin: 0px 1px;
  padding: 1px;
  font-size: 12px;
  text-align: center;
}
.time_diff_container p:hover {
  border: 1px solid transparent;
  background-color: #0058ff;
  color: white;
}

.time_diff_container a {
  border: 1px solid #0058ff;
  margin: 0px 1px;
  padding: 1px;
  font-size: 12px;
  text-align: center;
}

.orderTable tr td {
  width: "10%";
  /* margin-right: 10px; */
}
