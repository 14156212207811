.Beginner_Container {
  min-width: calc(100vh - 50px);
  display: flex;
}

.assets_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  /* padding-top: 5px; */
}

.asset_item {
  background-color: #050d22;
  color: white;
  padding: 4px;
  margin: 0px;
  text-align: center;
}

.ast_detail_container {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  /* grid-template-rows: 2fr 1fr 1fr; */
}

.ast_item_detail {
  display: flex;
  flex-direction: column;
  background-color: #050d22;
  color: #c9cacc;
  padding: 5px 8px;
  margin: 2px;
  justify-content: space-between;
}

.ast_i {
  display: flex;
  justify-content: space-between;
}

.exchange_inp_btn_container {
  display: flex;
  margin: 10px 0 3px 0;
  height: 46px;
}

.exchange_input {
  border: 1.3px solid white;
  background-color: #ffffff00;
  color: white;
  text-align: center;
  flex: 1;
  margin: 0 6px 0 0;
}

.exchange_dropdown {
  background-color: grey;
  border: 1.3px solid white;
  color: white;
  padding: 9px;
  height: 100%;
}

.exchange_btn {
  color: white;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.grey_btn {
  background-color: #8a8a8a;
  margin-top: 12px;
  border: 1px solid white;
  margin-bottom: 10px;
}

.blue_btn {
  background-color: #0058ff;
  margin-bottom: 0;
}

.history_container {
  background-color: #050d22;
  padding-bottom: 5px;
  /* height: 60%; */
  /* overflow: auto; */
  /* scrollbar-base-color: grey; */
  /* scrollbar-width: 8px; */
  /* scrollbar-color: grey; */
}

/* .history_item {
  padding: 5px 10px;
  display: flex;
  font-size: 13px;
  margin: 5px;
} */
