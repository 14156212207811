.right_trading_section {
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: #050d22;
  margin: 5px 7px 0 2px;
  border-radius: 8px;
  padding: 10px;
  color: #b9b9b9;
}

.buy_sell_btn {
  padding: 3px;
  width: 100px;
  color: white;
  text-align: center;
}

.pl_mi_btn {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #050d22;
  font-weight: bold;
}

.tr_section {
  background-color: black;
  margin-top: 10px;
  padding: 3px;
}

.pair_list_item {
  display: flex;
  justify-content: space-between;
  color: #969090;
}

.top_one {
  display: flex;
  justify-content: space-between;
}

.top_one p {
  border: 1px solid #b9b9b9;
  padding: 3px 10px;
  color: #b9b9b9;
}

.grey_btnss_con div {
  background-color: #495677;
  padding: 8px 10px;
  margin-top: 10px;
  color: white;
  text-align: center;
}

.blu_btn_con {
  background-color: #0058ff;
  padding: 8px 10px;
  margin-top: 10px;
  color: white;
  text-align: center;
}

th {
  position: sticky;
  top: 0;
  z-index: 2;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}